import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyle = createGlobalStyle`
  ${normalize}
  body {
    font-family: '微軟正黑體', sans-serif;
    position: relative;
  }
  input, select {
    ${tw`
      w-full
      rounded-none
      outline-none
      group-[.error]/error:border-b-orangeScale-500
      group-[.error]/error:placeholder:text-orangeScale-500
    `}
  }
  select {
    ${tw`
      bg-white
      bg-[url('/images/arrow_down.png')]
      bg-no-repeat
      [background-position: center right]
      appearance-none
      cursor-pointer
    `}
  }
  code.type {
    ${tw`text-pink-600`}
  }
  .demo-page h2 {
    ${tw`mb-2 text-xl`}
  }
  .demo-page h3 {
    ${tw`mb-1`}
  }
`;

const GlobalStyle = () => (
  <>
    {/* 若會破壞美觀再把 BaseStyles 拔掉 */}
    <BaseStyles />
    <CustomStyle />
  </>
);

export default GlobalStyle;
