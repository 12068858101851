import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Service } from 'utils';

const initialState = {
  brandMenu: [],
  categoryMenu: [],
  isCategoriesLoading: true,
  rightMenuList: [],
  activeCategoryId: 'brand',
};

export const fetchBrandMenu = createAsyncThunk(
  'menu/fetchBrandMenu',
  async (page) => {
    return await Service.brandMenu(page);
  }
);

export const fetchCategoryMenu = createAsyncThunk(
  'menu/fetchCategoryMenu',
  async () => {
    return await Service.categoryMenu();
  }
);

const rakumaMenuSlice = createSlice({
  name: 'rakumaMenu',
  initialState,
  reducers: {
    setActiveCategoryId: (state, { payload }) => {
      state.activeCategoryId = payload;
      // 以 user 點擊的分類 id 設定分類頁右半邊顯示的分類列表，品牌以 brand 辨識
      if (payload === 'brand') {
        state.rightMenuList = state.brandMenu;
      } else {
        state.rightMenuList = state.categoryMenu.filter(
          (val) => val.categoryId === payload
        );
      }
    },
    setRightMenuList: (state, { payload }) => {
      state.rightMenuList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandMenu.fulfilled, (state, action) => {
        state.activeCategoryId = 'brand';
        state.brandMenu = action.payload?.data.list || [];
        state.rightMenuList = action.payload?.data.list || [];
      })
      .addCase(fetchBrandMenu.rejected, (state, action) => {
        state.brandMenu = initialState.brandMenu;
      })
      .addCase(fetchCategoryMenu.pending, (state) => {
        state.isCategoriesLoading = true;
      })
      .addCase(fetchCategoryMenu.fulfilled, (state, { payload }) => {
        state.categoryMenu = payload?.data.list || [];
        state.isCategoriesLoading = false;
      })
      .addCase(fetchCategoryMenu.rejected, (state, action) => {
        state.categoryMenu = initialState.categoryMenu;
        state.isCategoriesLoading = false;
      });
  },
});
export const { setActiveCategoryId, setRightMenuList } =
  rakumaMenuSlice.actions;

export default rakumaMenuSlice.reducer;
