import { serviceProxy } from './serviceProxy';
import { constants } from './constants';

export const Service = {
  // common
  exchange: (env) =>
    serviceProxy({
      url: `/exchange/${env === 'dev' ? 'dev-' : ''}exchange.json`,
      noCache: true,
    }),
  apmWhiteList: () =>
    serviceProxy({
      url: '/web_fakeData/familymart/apm/apm_white_list.json',
      expect: true,
    }),
  // 地址資訊(國家、城市、區)
  cityList: () =>
    serviceProxy({
      url: `/purchase-manager/cities/TWN-2.json?timestamp=${Date.now()}`,
      cache: true,
    }),
  familyInfo: (reqData) =>
    serviceProxy({
      url: constants.familyInfoUrl(reqData),
      except: true,
      platform: 'bbn',
    }),
  cartCount: () =>
    serviceProxy({
      url: '/cart/platform-item-counts',
      platform: 'cart',
    }),
  // 加入購物車
  cartAddItem: (reqData) =>
    serviceProxy({
      url: '/cart/items',
      method: 'post',
      reqData,
      platform: 'cart',
    }),

  // token 解 userId
  decodeToken: () =>
    serviceProxy({
      url: '/members/data/member-id',
      platform: 'member',
    }),
  // 運費表(國際+國內)
  shippingFee: () =>
    serviceProxy({
      url: `/purchase-manager/shipping-methods/family-mart/methods.json?timestamp=${Date.now()}`,
      cache: true,
    }),

  // category: menu
  menu: () =>
    serviceProxy({
      url: `/family-mart/categories/2/published-categories.json?timestamp=${Date.now()}`,
      cache: true,
    }),
  // category: brand menu
  brandMenu: (page) =>
    serviceProxy({
      url: `/brands?page=${page}`,
      cache: true,
      platform: 'rakuma',
    }),
  // category: rakuma menu
  categoryMenu: () =>
    serviceProxy({
      url: `/categories`,
      cache: true,
      platform: 'rakuma',
    }),
  // category: result
  categoryQuery: ({ ids, excluded, page, sort }) =>
    serviceProxy({
      url: `/categories/products/for-pagination?categoryIds=${ids}&excludedSupplierKeys=${excluded}&languageCode=2&page=${page}&sort=${sort}`,
      platform: 'drug',
    }),

  // search: result
  search: ({ keyword, page, sort }) =>
    serviceProxy({
      url: `/products/search-by-name/for-pagination/${keyword}/2?categoryId=&page=${page}&sort=${sort}`,
      platform: 'drug',
    }),
  searchQuery: (keyword, reqData) =>
    serviceProxy({
      url: `/products/search-by-name-and-categories/for-pagination/${keyword}/2`,
      reqData,
      method: 'post',
      platform: 'drug',
    }),
  searchBrandQuery: (brandId, reqData) =>
    serviceProxy({
      url: `/brands/${brandId}/search-products-by-categories`,
      reqData,
      method: 'post',
      platform: 'drug',
    }),

  // TODO: AD 之後拉出來變成獨立的 service (/family-mart/pages/index.json)
  searchTags: () =>
    serviceProxy({
      url: `/family-mart/pages/index.json?timestamp=${Date.now()}`,
      cache: true,
    }),
  // 品牌模糊搜尋（目前只搜前兩個字）
  searchBrands: (prefix) =>
    serviceProxy({
      url: `/brandFuzzySearch?prefix=${prefix}`,
      cache: true,
      platform: 'rakuma',
    }),
  // 賣家搜尋（後端直接給全部，由前端 filter）
  searchSellers: () =>
    serviceProxy({
      url: `/sellerFuzzySearch`,
      cache: true,
      platform: 'rakuma',
    }),
  // 搜尋結果 Banner 版位
  searchResultBanner: () =>
    serviceProxy({
      url: `/family-mart/pages/index.json?timestamp=${Date.now()}`,
      cache: true,
    }),
  // 搜尋結果
  searchResults: (params) =>
    serviceProxy({
      url: `/search?${params || ''}`,
      cache: true,
      platform: 'rakuma',
    }),

  // TODO: AD 之後拉出來變成獨立的 service (/family-mart/pages/index.json)
  // Home
  home: () =>
    serviceProxy({
      url: `/family-mart/pages/index.json?timestamp=${Date.now()}`,
      cache: true,
    }),

  // Home promotion
  promotion: ({ promotionKey, blockId, start, end }) =>
    serviceProxy({
      url: `/promotions/block-products/${promotionKey}/${blockId}?start=${start}&end=${end}`,
      platform: 'fmmart',
    }),
  // TODO: 待 rakuma api 可串接時，進行串接修改
  // Rakuma Home Product
  rakumaHomeProduct: () =>
    serviceProxy({
      url: `/recommendation`,
      platform: 'rakuma',
    }),

  // Rakuma Home Store
  rakumaHomeStore: () =>
    serviceProxy({
      url: `/sellers?page=home`,
      platform: 'rakuma',
    }),
  // Hot Rakuma Category Advertisements
  rakumaHotCategories: () =>
    serviceProxy({
      url: `/web_fakeData/familymart/homeProduct/hotRakumaCategoryAdvertisements.json`,
      cache: true,
    }),

  // Product
  drugProduct: (id) =>
    serviceProxy({
      url: `/products/${id}?languageCode=2&platformId=2`,
      platform: 'drug',
    }),
  rakumaProduct: (id) =>
    serviceProxy({
      url: `/productFrontend?itemId=${id}`,
      platform: 'rakuma',
    }),
  rakumaTextTranslate: (id) =>
    serviceProxy({
      url: `/productTranslation?itemId=${id}`,
      platform: 'rakuma',
    }),

  // 新增追蹤商品
  favoriteCreate: (reqData) =>
    serviceProxy({
      url: '/members/tracking-products',
      platform: 'member',
      method: 'post',
      reqData,
    }),
  // 確認新增行為是否成功
  favoriteQuery: ({ queueIndex }) =>
    serviceProxy({
      url: `/members/tracking-products/add-result-check/${queueIndex}`,
      platform: 'member',
    }),
  // 退追商品
  favoriteDelete: ({ platformKey, channelKey, productId }) =>
    serviceProxy({
      url: `/members/tracking-products/${platformKey}/${channelKey}/${productId}`,
      platform: 'member',
      method: 'delete',
    }),
  // 追蹤商品清單
  favoriteList: ({ platformKey, channelKey, start, end }) =>
    serviceProxy({
      url: `/members/tracking-products/list/${platformKey}/${channelKey}?languageCode=2&startIndex=${start}&endIndex=${end}`,
      platform: 'member',
    }),
  // 追蹤商品ID清單
  favoriteIdList: ({ platformKey, channelKey }) =>
    serviceProxy({
      url: `/members/tracking-products/product-ids/${platformKey}/${channelKey}`,
      platform: 'member',
    }),

  // 購物車: 列表
  cartList: (reqData = 'drug') =>
    serviceProxy({
      url: `/cart/items?channelKey=${reqData}`,
      platform: 'cart',
    }),
  // 購物車: 編輯數量
  cartUpdateQuantity: (reqData) =>
    serviceProxy({
      url: '/cart/item-quantity',
      method: 'put',
      reqData,
      platform: 'cart',
    }),
  // 購物車: 編輯規格
  cartUpdateCartItem: (reqData) =>
    serviceProxy({
      url: '/cart/item',
      method: 'put',
      reqData,
      platform: 'cart',
    }),
  // 購物車: 刪除品項
  cartDeleteCartItem: (reqData) =>
    serviceProxy({
      url: '/cart/items',
      method: 'delete',
      reqData,
      platform: 'cart',
    }),

  // 收件資訊
  receiverList: () =>
    serviceProxy({
      url: '/members/bbn-main/delivery-information',
      platform: 'member',
    }),
  receiverDetail: (reqData) =>
    serviceProxy({
      url: `/members/bbn-main/delivery-information/${reqData}`,
      platform: 'member',
    }),
  receiverCreate: (reqData) =>
    serviceProxy({
      url: '/members/bbn-main/delivery-information',
      method: 'post',
      reqData,
      platform: 'member',
    }),
  receiverUpdate: (reqData) =>
    serviceProxy({
      url: `/members/bbn-main/delivery-information/${reqData.id}`,
      method: 'put',
      reqData: reqData.data,
      platform: 'member',
    }),
  // 新增與編輯後的查詢
  receiverQuery: (reqData) =>
    serviceProxy({
      url: `/members/bbn-main/delivery-information/check-inserting-result/${reqData}`,
      platform: 'member',
    }),
  receiverDelete: (reqData) =>
    serviceProxy({
      url: `/members/bbn-main/delivery-information/${reqData}`,
      method: 'delete',
      platform: 'member',
    }),

  // 全家門市: storeGroupId 固定為 1
  storeList: () =>
    serviceProxy({
      url: '/members/delivery-convenience-stores/1',
      platform: 'member',
    }),
  // 此為背景執行
  storeCreate: () =>
    serviceProxy({
      url: '/members/delivery-convenience-stores/1',
      method: 'post',
      platform: 'member',
    }),
  storeCreateQuery: (reqData) =>
    serviceProxy({
      url: `/members/delivery-convenience-stores/check-inserting-result/1/${reqData}`,
      platform: 'member',
    }),
  storeDetail: (reqData) =>
    serviceProxy({
      url: `/members/delivery-convenience-stores/1/${reqData}`,
      platform: 'member',
    }),
  storeUpdate: (reqData) =>
    serviceProxy({
      url: `/members/delivery-convenience-stores/1/${reqData}/preference`,
      method: 'put',
      platform: 'member',
    }),
  storeDelete: (reqData) =>
    serviceProxy({
      url: `/members/delivery-convenience-stores/1/${reqData}`,
      method: 'delete',
      platform: 'member',
    }),

  // 結帳頁
  cartItemEncode: (reqData) =>
    serviceProxy({
      url: '/ordersearch.php', // 送給主站取運費活動前，需將購物車資訊加密
      method: 'post',
      reqData,
      platform: 'bbn',
    }),
  eventQualify: (reqData) =>
    serviceProxy({
      url: '/campaign_rule_check_family.php?type=market&step=1',
      method: 'post',
      reqData,
      platform: 'bbnMarket',
    }),
  orderCreate: (reqData) =>
    serviceProxy({
      url: '/order/add',
      method: 'post',
      reqData,
      platform: 'order',
    }),
  orderQuery: (reqData) =>
    serviceProxy({
      url: `/order/get-order-for-adding-result?date=${reqData.date}&queueIndex=${reqData.queueIndex}`,
      platform: 'order',
    }),
  // 會員中心
  memberData: (reqHeaders, token) =>
    serviceProxy({
      url: `/member/MemberController.php?method=getMemberEncodedData&memberToken=${token}`,
      platform: 'bbn',
      reqHeaders,
    }),

  // 訂單列表和細節
  orderSearch: (reqData) =>
    serviceProxy({
      url: '/ordersearch.php',
      method: 'post',
      platform: 'bbn',
      reqData,
    }),
  orderCancel: (reqData) =>
    serviceProxy({
      url: '/order/cancel-order',
      method: 'put',
      platform: 'order',
      reqData,
    }),

  // 使用者所有訂單問答列表
  userAsk: (reqData) =>
    serviceProxy({
      url: '/erp/member_question_main.php',
      method: 'post',
      platform: 'bbnMarket',
      reqData,
    }),
  // 訂單問答內容
  qaDetail: (reqData, id) =>
    serviceProxy({
      url: `/erp/member_question_list.php?order_id=${id}`,
      method: 'post',
      platform: 'bbnMarket',
      reqData,
    }),
  // 訂單問答內容
  productQaDetail: (reqData) =>
    serviceProxy({
      url: '/erp/member_question_list.php?',
      method: 'post',
      platform: 'bbnMarket',
      reqData,
    }),
  // 更新訂單問答
  qaUpdateContent: (reqData) =>
    serviceProxy({
      url: '/erp/member_question_insert.php',
      method: 'post',
      platform: 'bbnMarket',
      reqData,
    }),
  productQaUpdateContent: (reqData) =>
    serviceProxy({
      url: '/erp/member_shopping_cart_question_add.php',
      method: 'post',
      platform: 'bbnMarket',
      reqData,
    }),
  // 取得複數訂單問答未讀狀態
  qaStatusList: (reqData) =>
    serviceProxy({
      url: '/member_question_status.php',
      method: 'post',
      platform: 'bbn',
      reqData,
    }),
  // 訂單問答更新為已讀狀態
  qaHasRead: (reqData) =>
    serviceProxy({
      url: '/member_question_status.php?upd_read=1',
      method: 'post',
      platform: 'bbn',
      reqData,
    }),
  // 取得QA問答類型清單
  qaTypeList: () =>
    serviceProxy({
      url: '/erp/member_question_type.php',
      method: 'get',
      platform: 'bbnMarket',
    }),
  // 訂單問答上傳圖片並取得網址
  qaUploadImage: (reqData) =>
    serviceProxy({
      url: '/s3_upload.php ',
      method: 'post',
      platform: 'bbn',
      reqData,
      isMultipart: true,
    }),

  // 折扣碼兌換折價券
  codeConvertCoupon: ({ code, uid }) =>
    serviceProxy({
      url: `/code_redeem_ticket.php?code=${code}&member_id=${uid}`,
      platform: 'bbnMarket',
    }),
  // 折價券+詳細: 後端僅提供列表的 API，詳細頁需自行過濾(待未來換新的行銷工具後，可移除這樣的垃圾)
  couponList: (reqData) =>
    serviceProxy({
      url: `/my_ticket.php?supplier=familyMart&member_id=${reqData}`,
      platform: 'bbnMarket',
    }),
  // 可使用折價券(結帳頁)
  availableCouponList: (reqData) =>
    serviceProxy({
      url: '/campaign_rule_check.php',
      method: 'post',
      reqData,
      platform: 'bbnMarket',
    }),
  // TODO: 可考慮是否與可使用折價券合併
  // 可使用全家店舖代碼(結帳頁)
  availableFamilyCode: (reqData) =>
    serviceProxy({
      url: '/campaign_rule_check.php?type=code',
      method: 'post',
      reqData,
      platform: 'bbnMarket',
    }),
  // 取得折扣碼對應全家店舖資訊(結帳頁)
  familyCodeWithStore: (reqData) =>
    serviceProxy({
      url: `/discounts/store-info-by-discount-no/${reqData}`,
      platform: 'fmmart',
    }),
};
