import { useRef, useState } from 'react';

export const useAutoComplete = ({ delay = 300, source, onChange }) => {
  const [myTimeout, setMyTimeOut] = useState(setTimeout(() => {}, 0));
  const listRef = useRef();
  const [suggestions, setSuggestions] = useState([]);
  const [isBusy, setBusy] = useState(false);

  function delayInvoke(cb) {
    if (myTimeout) {
      clearTimeout(myTimeout);
    }
    setMyTimeOut(setTimeout(cb, delay));
  }

  function selectOption(index) {
    if (index > -1) {
      onChange(suggestions[index]);
    }
    clearSuggestions();
  }

  async function getSuggestions(searchTerm) {
    if (searchTerm && source) {
      const options = await source(searchTerm);
      setSuggestions(options);
    }
  }

  function clearSuggestions() {
    setSuggestions([]);
  }

  function onTextChange(searchTerm) {
    setBusy(true);
    clearSuggestions();
    delayInvoke(() => {
      getSuggestions(searchTerm);
      setBusy(false);
    });
  }

  return {
    bindOption: {
      onClick: (e) => {
        let nodes = Array.from(listRef.current.children);
        selectOption(nodes.indexOf(e.target.closest('li')));
      },
    },
    bindInput: {
      onChange: (e) => onTextChange(e.target.value),
    },
    bindOptions: {
      ref: listRef,
    },
    isBusy,
    suggestions,
  };
};
