import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, formatConversationDay, constants, suspense } from 'utils';
import { fetchUserId } from 'reduxs/commonSlice';

const { uid, token } = constants;

// TODO: 外層再包一個 base 先 fetch userId，這邊直接取用就好
export const fetchOrderQAContent = createAsyncThunk(
  'orderQA/fetchOrderQAContent',
  async (reqData, { dispatch, rejectWithValue }) => {
    let userId = uid;

    // 有 uid 重新 dispatch
    if (!userId) {
      const resDataUserId = await dispatch(fetchUserId()).unwrap();
      userId = resDataUserId.data.id;
    }
    const authObj = { ez1: token };
    const serviceName =
      reqData.type === 'order' ? 'qaDetail' : 'productQaDetail';
    const requestBody =
      reqData.type === 'order'
        ? authObj
        : { ...authObj, productId: reqData.id };
    try {
      const resData = await Service[serviceName](
        requestBody,
        reqData.type === 'order' ? reqData.id : null
      );

      if (resData?.hasError) {
        return rejectWithValue('內容讀取失敗');
      }

      if (resData) {
        if (resData?.ErrorMsg) {
          return rejectWithValue('');
        }
        return reqData.type === 'order'
          ? { list: [...resData], type: reqData.type }
          : { ...resData, type: reqData.type };
      }
    } catch (err) {
      return rejectWithValue('內容讀取失敗');
    }
  }
);

export const fetchQaStatusList = createAsyncThunk(
  'orderQA/fetchQaStatusList',
  async (_, { dispatch, rejectWithValue }) => {
    let userId = uid;
    // 有 uid 重新 dispatch
    if (!userId) {
      const resDataUserId = await dispatch(fetchUserId()).unwrap();
      userId = resDataUserId.data.id;
    }

    const orderIds = await Service.orderSearch({
      ez1: constants.token,
      status: 'getorderno',
      platform: 'familyMart',
      days: 180, // default = 365, range = 180~365
      records: 100, // default = 100, range = 1~100
    });

    try {
      const resData = await Service.qaStatusList({
        orderIds: orderIds.orderNo,
        memberId: userId,
      });
      if (resData?.hasError) {
        return rejectWithValue('');
      }
      if (resData) {
        if (resData?.ErrorMsg) {
          return {};
        }
        return resData;
      }
    } catch (err) {
      return rejectWithValue('');
    }
  }
);

export const fetchQaTypeList = createAsyncThunk(
  'orderQA/fetchQaTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const resData = await Service.qaTypeList();
      if (resData?.hasError) {
        return rejectWithValue('提問種類出錯');
      }
      if (resData) {
        if (resData?.ErrorMsg) {
          return [];
        }
        return resData;
      }
    } catch (err) {
      return rejectWithValue('提問種類出錯');
    }
  }
);

export const updateOrderHasRead = createAsyncThunk(
  'orderQA/updateOrderHasRead',
  async ({ type, id }, { dispatch, rejectWithValue }) => {
    try {
      let userId = uid;
      // 有 uid 重新 dispatch
      if (!userId) {
        const resDataUserId = await dispatch(fetchUserId()).unwrap();
        userId = resDataUserId.data.id;
      }
      const authObj = { ez1: token };
      const requestBody =
        type === 'order'
          ? { ...authObj, orderIds: [id] }
          : { ...authObj, productIds: [id] };
      const resData = await Service.qaHasRead(requestBody);
      if (resData?.hasError) {
        return rejectWithValue('');
      }
      return resData;
    } catch (err) {
      return rejectWithValue('');
    }
  }
);

export const updateOrderQA = createAsyncThunk(
  'orderQA/updateOrderQA',
  async ({ type, data }, { dispatch, rejectWithValue }) => {
    let userId = uid;
    // 有 uid 重新 dispatch
    if (!userId) {
      const resDataUserId = await dispatch(fetchUserId()).unwrap();
      userId = resDataUserId.data.id;
    }

    const serviceName =
      type === 'order' ? 'qaUpdateContent' : 'productQaUpdateContent';
    try {
      const resData = await Service[serviceName]({
        memberId: userId,
        ez1: token,
        ...data,
      });
      // 問答非即時更新，等待2秒讓畫面先顯示暫存內容
      await suspense(2000);
      if (type === 'order') {
        if (resData?.hasError) {
          return rejectWithValue('傳送失敗');
        }
        if (resData?.Status && resData?.Status === 'T') {
          return resData;
        }
      } else {
        return resData?.hasError || resData?.code < 1
          ? rejectWithValue('傳送失敗')
          : resData;
      }
      return rejectWithValue('傳送失敗');
    } catch (err) {
      return rejectWithValue('傳送失敗');
    }
  }
);

const formatQA = (payload) => {
  const res = {};

  if (payload) {
    payload.forEach((item) => {
      const regexp = /<a\s+(href="[^"]+")>/gi;
      let content = item.content;
      content = content.replaceAll(regexp, `<a $1 target="_blank">`);

      const [date, time] = item.ins_date.split(' ');
      const formatDay = formatConversationDay(date);
      if (!res[formatDay]) {
        res[formatDay] = [];
      }
      const newItem = {
        date: formatDay,
        time: time,
        id: item.id,
        type: item.user_type,
        content,
        files: item.file_trk ? item.file_trk?.filter((file) => file) : [],
      };

      res[formatDay].push(newItem);
    });
  }

  return res;
};

const initialState = {
  data: {},
  mainId: null,
  productInfo: {},
  qaStatusList: {},
  typeList: [],
  unreadQA: false,
  loading: true,
  updateLoading: false,
  message: '',
  updateError: '',
  error: '',
};

const qaSlice = createSlice({
  name: 'orderQA',
  initialState,
  reducers: {
    resetData: () => initialState,
    resetOrderQAContent: (state) => {
      state.data = initialState.data;
      state.mainId = initialState.mainId;
      state.productInfo = initialState.productInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderQAContent.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchOrderQAContent.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.type === 'product') {
          state.data = formatQA(payload?.list);
          state.mainId = payload?.mainId;
          state.productInfo = payload?.productInfo;
        } else {
          state.data = formatQA(payload.list);
        }
      })
      .addCase(fetchOrderQAContent.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      // fetchQaStatusList
      .addCase(fetchQaStatusList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchQaStatusList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.qaStatusList = payload;
        state.unreadQA = !Object.values(payload).every(
          (value) => value === false
        );
      })
      .addCase(fetchQaStatusList.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })

      // updateOrderQA
      .addCase(updateOrderQA.pending, (state) => {
        state.updateLoading = true;
        state.updateError = '';
      })
      .addCase(updateOrderQA.fulfilled, (state, { payload }) => {
        state.updateLoading = false;
        state.message = payload;
      })
      .addCase(updateOrderQA.rejected, (state, { payload }) => {
        state.updateLoading = false;
        state.updateError = payload;
      })

      // updateOrderHasRead
      .addCase(updateOrderHasRead.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(updateOrderHasRead.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      // fetchQaTypeList
      .addCase(fetchQaTypeList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.typeList = payload;
      })
      .addCase(fetchQaTypeList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetData, resetOrderQAContent } = qaSlice.actions;
export default qaSlice.reducer;
