import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { serviceProxy, Service } from 'utils';

// 搜尋標籤、熱門品牌
export const fetchRakumaSearchTagsAndAds = createAsyncThunk(
  'rakumaSearch/fetchRakumaSearchTagsAndAds',
  async (_, { rejectWithValue }) => {
    try {
      const res = await Service.searchTags();
      const { positions } = res.search;

      const contents = await serviceProxy({
        url: positions,
        except: true,
        cache: true,
      });
      const keywordTags = await serviceProxy({
        url: contents?.keywordTags['rakuma'][0],
        except: true,
        cache: true,
      });
      const brandAdvertisements = await serviceProxy({
        url: contents?.searchBrandAdvertisements['rakuma'][0],
        except: true,
        cache: true,
      });
      return { keywordTags, brandAdvertisements };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: null,
  error: null,
  response: {
    keywordTags: [],
    brandAdvertisements: [],
  },
  init: true,
};

const rakumaSearchSlice = createSlice({
  name: 'rakumaSearch',
  initialState,
  reducers: {
    resetRakumaSearchTagsAndAds: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRakumaSearchTagsAndAds.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRakumaSearchTagsAndAds.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.response = payload;
        state.init = false;
      })
      .addCase(fetchRakumaSearchTagsAndAds.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetRakumaSearchTagsAndAds } = rakumaSearchSlice.actions;
export default rakumaSearchSlice.reducer;
