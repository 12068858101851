import React, { useEffect, useCallback } from 'react';
import { apm } from '@elastic/apm-rum';
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import {
  getParams,
  removeCookie,
  constants,
  sendSlackMessage,
  uuidGenerator,
} from 'utils';
import {
  fetchCityList,
  fetchCartCount,
  useExchangeQuery,
  setAdChannelKey,
} from 'reduxs/commonSlice';
import { fetchFamilyInfo } from 'reduxs/familyInfoSlice';
import { fetchShippingFee } from 'reduxs/marketSlice';
import Header from 'containers/header';
import Error from 'components/error';
import { StyledWrap, StyledMain, StyledContainer } from './styled';

const { token } = constants;

const Frame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  const { status, bibianToken } = useSelector((state) => state.familyInfo);
  const { logData } = useSelector((state) => state.error);
  const isStageOrProd =
    process.env.REACT_APP_ENV === 'stage' ||
    process.env.REACT_APP_ENV === 'production';

  // 紀錄曾經捲軸位置，不置頂判斷
  const getKey = useCallback((location) => {
    // 要擴充的頁面往後加
    const regex = /^(\/pay\/?[^/]*)$/g;
    return regex.test(pathname) ? pathname : location.key;
  });

  // TODO: 需統整每一頁的 padding-top
  // 額外處理 style 的 page key
  const isExceptPage = /(^\/$|qa|product|cart)/g.test(pathname);
  const isFavoritePage = /(favorite)/g.test(pathname);
  const isSearchPage = /^\/search$/.test(pathname);

  // 額外處理無限滾動列表(v1)的 page key
  const isInfinitePage = /(search|category)/g.test(pathname);

  // 全家代客購買: 確認網址params中是否有from=185
  let cleanTag = null;
  const params = getParams();
  const fromTags = params?.from;

  if (fromTags) {
    cleanTag = fromTags?.find((tag) => tag === '185') ?? null;
  }

  // 取匯率，約 10min 重新 fetch
  useExchangeQuery(null, {
    pollingInterval: 10 * 60 * 1000,
    refetchOnFocus: true,
  });

  // 行銷工具、購物車 count
  useEffect(() => {
    dispatch(fetchCityList());
    dispatch(fetchShippingFee());
    dispatch(fetchCartCount());

    if (!/(search\/|search-result)/g.test(pathname) && pathname !== '/') {
      dispatch(setAdChannelKey('rakuma'));
    }

    const apmUuid = localStorage.getItem('apmUuid');
    if (!apmUuid) {
      localStorage.setItem('apmUuid', uuidGenerator());
    }
  }, []);

  // 全家代客購買: 專員從185連到全家專案頁面的狀況
  // params 中有 from=185 時，清除既有的會員 cookie 及會員 id
  useEffect(() => {
    const refetchUser = async () => {
      try {
        const trans = params?.Trans_info2?.[0] ?? '';
        removeCookie('ez1');
        removeCookie('uid');
        removeCookie('Trans_info2');
        await dispatch(fetchFamilyInfo()).unwrap();
        // 消除params, 避免進入重複reload
        navigate(`/?Trans_info2=${trans}`);
      } catch (err) {
        console.log('185 error?', err);
      }
    };
    if (cleanTag) {
      refetchUser();
    }
  }, [cleanTag]);

  useEffect(() => {
    // 有185 tag時 不進行token判斷
    if (cleanTag) {
      return;
    }
    /**
     * 背景自動判斷登入狀態
     * status: false的情況代表未執行過
     * token: cookie沒有值, 解析密文取得新的token
     */
    if (!status && !token) {
      dispatch(fetchFamilyInfo());
    }
    /**
     * status: pending的情況代表未和比比昂綁定或註冊, 無法取得token
     * status: failed代表密文解析失敗或其他失敗情境, 無法取得token
     * token: cookie有值代表使用舊快取, 需要重整browser來更新
     */
    if ((status === 'failed' && token) || (status === 'pending' && token)) {
      navigate(0);
    }
    /**
     * status: success的情況, 已執行且成功取得新的token
     * bibianToken: redux暫存狀態的值, 用來和現存的cookie比對
     * token: bibianToken有值且兩者不相同, 代表cookie用到舊快取, 需要重整browser來更新
     */
    if (status === 'success' && bibianToken && bibianToken !== token) {
      navigate(0);
    }
  }, [status, token, bibianToken, cleanTag]);

  // log error
  const logError = (error) => {
    if (process.env.REACT_APP_ENV === 'production') {
      sendSlackMessage({
        title: `[${process.env.REACT_APP_ENV}] ${pathname}${search}`,
        message: error,
        ...(logData
          ? { resData: `\`\`\`${JSON.stringify(logData)}\`\`\`` }
          : ''),
      });
    }

    apm.captureError(
      new Error(
        `[${process.env.REACT_APP_ENV}] ${pathname}${search}\n\n${error}`
      )
    );
  };

  return (
    <StyledWrap
      className={`group/infinite ${isInfinitePage ? 'is-infinite' : ''}`}
      isSearch={isSearchPage}
    >
      <Header />
      <StyledMain
        {...(pathname.includes('/demo') && { className: 'demo-page' })}
        isExcept={isExceptPage}
        isFavoritePage={isFavoritePage}
      >
        <StyledContainer className="container">
          <ErrorBoundary
            resetKeys={[pathname]}
            FallbackComponent={Error}
            {...(isStageOrProd && { onError: { logError } })}
          >
            <Outlet />
          </ErrorBoundary>
        </StyledContainer>
      </StyledMain>

      <ScrollRestoration getKey={getKey} />
    </StyledWrap>
  );
};

export default Frame;
