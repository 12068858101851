import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { serviceProxy, Service } from 'utils';

let tempSearch = {};
let tempBrand = {};

// 搜尋標籤
export const fetchSearchTags = createAsyncThunk(
  'search/fetchSearchTags',
  async (_, { rejectWithValue }) => {
    try {
      const res = await Service.searchTags();
      const { positions } = res.search;

      const contents = await serviceProxy({
        url: positions,
        except: true,
        cache: true,
      });
      const keywordTags = await serviceProxy({
        url: contents?.keywordTags.all[0],
        except: true,
        cache: true,
      });

      return keywordTags;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: null,
  error: null,
  tags: [],
  init: true,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearchTags: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearchTags.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tags = payload;
        state.init = false;
      })
      .addCase(fetchSearchTags.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetSearchTags } = searchSlice.actions;
export default searchSlice.reducer;

const defaultParams = {
  products: [],
  totalPages: 0,
};

// 搜尋結果列表
export const searchApi = createApi({
  reducerPath: 'searchApi',
  endpoints: (builder) => ({
    searchByKeyword: builder.query({
      queryFn: async ({ keyword, channelIds, page }) => {
        const resData = await Service.searchQuery(keyword, {
          categoryIds: channelIds.join(','),
          page,
          sort: 'id_desc',
        });
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關搜尋',
          };
        }

        const { totalPages, products } = data;

        // 捲動開始，須預塞第一頁資料，但捲到第二頁才寫入 localStorage
        if (page === 1) {
          tempSearch = {
            ...tempSearch,
            1: products,
          };
        } else {
          tempSearch = {
            ...tempSearch,
            [page]: products,
          };

          localStorage.setItem('pageData', JSON.stringify(tempSearch));
        }

        return {
          data: {
            products,
            totalPages,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useSearchByKeywordQuery } = searchApi;
export const resetSearch = () => searchApi.util.resetApiState();

// 品牌列表
export const brandApi = createApi({
  reducerPath: 'brandApi',
  endpoints: (builder) => ({
    searchByBrand: builder.query({
      queryFn: async ({ brandId, channelIds, page }) => {
        const resData = await Service.searchBrandQuery(brandId, {
          categoryIds: channelIds.join(','),
          sort: 'id_desc',
          page,
        });
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關品牌',
          };
        }

        const { totalPages, products } = data;

        // 捲動開始，須預塞第一頁資料，但捲到第二頁才寫入 localStorage
        if (page === 1) {
          tempBrand = {
            ...tempBrand,
            1: products,
          };
        } else {
          tempBrand = {
            ...tempBrand,
            [page]: products,
          };

          localStorage.setItem('pageData', JSON.stringify(tempBrand));
        }

        return {
          data: {
            products,
            totalPages,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useSearchByBrandQuery } = brandApi;
export const resetSearchBrand = () => brandApi.util.resetApiState();
