import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, formatFullTime } from 'utils';

const formatAsk = (type, data, prevList) => {
  const empty = [];

  if (data?.question_list) {
    const res = data?.question_list.map((item) => ({
      id_que: item.id_que,
      orderId: item.order_id,
      productId: item.productId,
      productName: item.productName,
      time: formatFullTime(item.ask_time, 'slash'),
      image:
        type === 'orderList' ? item.file_trk?.[0] ?? '' : item.productImage,
      content: item.content,
      supplier: item.supplier,
    }));
    return prevList ? [...prevList, ...res] : res;
  }
  return empty;
};

const initialState = {
  qaOrder: {
    isLoading: true,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
  qaProduct: {
    isLoading: true,
    list: [],
    totalCount: 0,
    totalPages: 0,
  },
};

// 搜尋結果
export const fetchQaList = createAsyncThunk(
  'ask/fetchQaList',
  async (reqData, { getState }) => {
    const resData = await Service.userAsk({
      ...reqData,
      supplier: 'familyMart',
      from: 'familyMart',
    });

    if (resData?.hasError) {
      return {
        type: reqData.searchType,
        data: {
          ...initialState.qaOrder,
          isLoading: false,
          error: '系統忙線中，請稍後再試',
        },
      };
    }
    if (resData?.ErrorMsg) {
      return {
        type: reqData.searchType,
        data: {
          ...initialState.qaOrder,
          isLoading: false,
          error: resData.ErrorMsg,
        },
      };
    }

    const { qaList } = getState();
    const prevList =
      reqData.searchType === 'orderList'
        ? qaList.qaOrder?.list
        : qaList.qaProduct?.list;

    const formatData = {
      isLoading: false,
      list: formatAsk(reqData.searchType, resData, prevList),
      totalCount: resData?.total ?? 0,
      currentPage: reqData.page,
      totalPages: Math.ceil(resData?.total / 10) ?? 0,
    };

    return { type: reqData.searchType, data: formatData };
  }
);

const qaListSlice = createSlice({
  name: 'qaList',
  initialState,
  reducers: {
    resetQaList: (state) => {
      state.qaOrder = initialState.qaOrder;
      state.qaProduct = initialState.qaProduct;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchQaList.pending, (state) => {
        state.qaOrder.isLoading = true;
        state.qaProduct.isLoading = true;
      })
      .addCase(fetchQaList.fulfilled, (state, { payload }) => {
        if (payload.type === 'orderList') {
          state.qaOrder = payload.data;
        } else {
          state.qaProduct = payload.data;
        }
      })
      .addCase(fetchQaList.rejected, (state) => {
        state.qaOrder = { ...initialState.qaOrder, isLoading: false };
        state.qaProduct = { ...initialState.qaProduct, isLoading: false };
      });
  },
});

export const { resetQaList } = qaListSlice.actions;
export default qaListSlice.reducer;
