import { getCookie } from './cookiesFunction';
import { dayjs } from './dayjs';
import { calculate } from './calculate';

/**
 * 千分位
 * @param {number} price - 金額
 * @returns {string} value
 */
export const formatComma = (price) => {
  const stringPrice = price ? String(price) : '0';
  return `${String(stringPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

/**
 * 公克(g)轉公斤(kg)
 * @param {number} weight - 公克(g)
 * @returns {number} 公斤(kg)
 * @example
 * formatWeight(1000) -> 1
 * formatWeight(155) -> 0.155
 */
export const formatWeight = (weight) => {
  return calculate(weight / 1000);
};

/**
 * 匯率轉換: 匯率從 localStorage 取
 * @param {number} price - 金額(外幣)
 * @param {number} param - 匯率: 因執行順序問題必須從外面傳遞，確保此 fn 不是 NaN (因購物車 createSelector 不知道該怎麼取用 RTK query 的值，先保留預設值)
 * @returns {object}
 */
export const formatCurrencyRule = (price, param) => {
  const cacheExchange = getCookie('exchange');
  const exchange = param ? param : +cacheExchange;

  // 幣別匯率邏輯 (先預留 USD)
  const rule = {
    JPY: price,
    TWD: Math.ceil(price * exchange),
    USD: +(price / 151.59).toFixed(2), // 舉例用先寫死匯率,
  };

  return rule;
};

/**
 * 匯率轉換: 匯率從 localStorage 取
 * @param {number} price - 金額(台幣)
 * @param {number} param - 匯率: 因執行順序問題必須從外面傳遞，確保此 fn 不是 NaN (因購物車 createSelector 不知道該怎麼取用 RTK query 的值，先保留預設值)
 * @param {string} type - 最大值(maxPrice) or 最小值(minPrice)
 * @returns {number}
 */
export const formatCurrencyReverseRule = (type, price, param) => {
  const cacheExchange = getCookie('exchange');
  const exchange = param ? param : +cacheExchange;
  const exchangePrice = price / exchange;

  return type === 'minPrice' ? Math.floor(exchangePrice) : Math.ceil(exchangePrice);
};

/**
 * 年月日 + 週
 * @param {number} day 日
 * @returns {string} YYYY/MM/DD (dd)
 * formatConversationDay('2024-05-20 10:21:09') -> 2024/05/20 (一)
 */
export const formatConversationDay = (day) => {
  return dayjs(day).format('YYYY/MM/DD (dd)');
};

/**
 * 時間
 * @param {number} day new Date()
 * @returns {string} HH:mm:ss
 * formatTime('Sun Jun 09 2024 18:50:56 GMT+0800 (台北標準時間)') -> 18:50:56
 */
export const formatTime = (day) => {
  return dayjs(day).format('HH:mm:ss');
};

/**
 * 年/月/日 + 時分秒 or 年-月-日 + 時分秒 or
 * @param {number} day 日
 * @param {number} type slash or dash or day
 * @param {number} time true or false
 * @returns {string} YYYY/MM/DD (dd)
 * formatWeekDay('2024-05-20 10:21:09') -> 2024/05/20 (一)
 */
export const formatFullTime = (day, type, time = true) => {
  let timeString = ' HH:mm:ss';
  if (!time) {
    timeString = '';
  }
  if (type === 'slash') {
    return dayjs(day).format(`YYYY/MM/DD${timeString}`);
  }
  if (type === 'dash') {
    return dayjs(day).format(`YYYY-MM-DD${timeString}`);
  }
  return dayjs(day).format(`YYYY/MM/DD${timeString}`);
};
