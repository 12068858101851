import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProxy } from 'utils';

export const initialState = {
  loading: null,
  error: null,
  init: true,
  response: {
    announcements: {
      data: [],
      url: '',
    },
    banners: {
      data: [],
      url: '',
    },
    categoryAdvertisements: {
      data: [],
      url: '',
    },
    firstAdvertisements: {
      data: [],
      url: '',
    },
    secondAdvertisements: {
      data: [],
      url: '',
    },
    thirdAdvertisements: {
      data: [],
      url: '',
    },
    everyoneWatchingAdvertisements: {
      data: [],
      url: '',
    },
    latestInformationAdvertisements: {
      data: [],
      url: '',
    },
    brandAdvertisements: {
      data: [],
      url: '',
    },
    coverAdvertisements: {
      data: [],
      url: '',
    },
  },
};

// Ad 區塊與頁面
export const fetchHomeAd = createAsyncThunk(
  'home/fetchHomeAd',
  async (_, { getState }) => {
    const state = getState();
    const channelKey = state.common.adChannelKey;
    const adUrl = state.home.adUrl;
    const data = { ...initialState.response };
    const urls = await serviceProxy({
      url: adUrl,
      except: true,
      cache: true,
    });

    for (let key in urls) {
      if (urls[key]?.[channelKey]) {
        const section = await serviceProxy({
          url: urls[key][channelKey][0],
          except: true,
          // cache: true,
        });
        data[key] = {
          data: section,
          url: urls[key]?.[channelKey]?.url ?? '',
        };
      }
    }
    return data;
  }
);

const homeAdSlice = createSlice({
  name: 'homeAd',
  initialState,
  reducers: {
    resetHomeAd: (state) => {
      state.loading = initialState.loading;
      state.response = initialState.response;
      state.error = initialState.error;
      state.init = initialState.init;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeAd.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHomeAd.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.response = payload;
        state.init = false;
      })
      .addCase(fetchHomeAd.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetHomeAd } = homeAdSlice.actions;

export default homeAdSlice.reducer;
