import tw, { styled, css } from 'twin.macro';
import Container from 'components/container';
import {
  MenuIcon,
  SearchIcon,
  CartIcon,
  BibianUserIcon,
  LeftArrowIcon,
  HomeColorIcon,
  HeartIcon,
} from 'components/icon';
import Badge from 'components/badge';
import Links from 'components/links';
import Button from 'components/button';

const styleConfig = {
  normal: tw`text-2xl text-grayScale-700`,
  enlarge: tw`text-3xl text-grayScale-700`,
};

export const StyledHeader = styled.header`
  ${tw`
    w-full
    h-full
    max-h-11
    bg-white
    flex
    items-center
    z-30
    shadow-[0_2px_8px_0]
    shadow-grayScale-500/10
    group-[.is-infinite]/infinite:fixed
  `}
`;

export const StyledContainer = styled(Container)`
  ${tw`
    h-full
    flex
    justify-between
    items-center
    px-3
  `}
`;

export const StyledWrap = styled.div`
  ${tw`flex items-center gap-[6px]`}
`;

export const StyledCenteredWrap = styled(StyledWrap)(({ type }) => [
  type.includes('search') && tw`flex-1`,
]);

export const StyledLeftWrap = styled(StyledWrap)(({ type }) => [
  tw`ml-1`,
  type.includes('search') ? tw`mr-[10px]` : tw`min-w-[88px]`,
]);

export const StyledRightWrap = styled(StyledWrap)(({ type, isLogged }) => [
  tw`min-w-[88px] mr-1 justify-end`,
  type.includes('search') && tw`min-w-[0px]`,
  (type === 'search-keyword' || type === 'search-result') &&
    !isLogged &&
    tw`min-w-[44px] mr-0`,
]);

export const StyledTitle = styled.div(({ type }) => [
  tw`text-lg font-medium`,
  (type === 'qa-order-id' || type === 'qa-product-id') && tw`text-base`,
  type === 'qa-product-id' &&
    tw`
      [line-clamp: 1]
      [-webkit-line-clamp: 1]
      [text-overflow:ellipsis]
      [-webkit-box-orient:vertical]
      [display:-webkit-box]
      overflow-hidden
    `,
]);

export const StyledIconBox = styled.button`
  ${tw`p-1 outline-none`}
`;

export const StyledLinks = styled(Links)(({ status }) => [
  tw`relative p-1`,
  status === 'true' &&
    css`
      &::after {
        ${tw`content-[''] absolute top-2 -right-1 w-1.5 h-1.5 rounded-full bg-redScale-500`}
      }
    `,
]);

export const StyledButton = styled(Button)`
  ${tw`relative p-1`}
`;

export const StyledBadge = styled(Badge)`
  ${tw`
    text-[10px]
    rounded-lg
    absolute
    px-1
    py-0.5
    top-1
    right-1
  `}
  ${({ text }) => text === '99+' && tw`-right-1.5`}
`;

export const StyledLeftArrowIcon = styled(LeftArrowIcon)(() => [
  styleConfig.normal,
]);

export const StyledMenuIcon = styled(MenuIcon)(() => [styleConfig.normal]);

export const StyledSearchIcon = styled(SearchIcon)(() => [styleConfig.normal]);

export const StyledCartIcon = styled(CartIcon)(() => [styleConfig.enlarge]);

export const StyledBibianUserIcon = styled(BibianUserIcon)(() => [
  styleConfig.enlarge,
]);

export const StyledHomeColorIcon = styled(HomeColorIcon)`
  ${tw`text-4xl`}
`;

export const StyledHeartIcon = styled(HeartIcon)(() => [styleConfig.enlarge]);
