import tw, { styled } from 'twin.macro';
import Container from 'components/container';

export const StyledWrap = styled.div(({ isSearch }) => [
  tw`
    h-screen
    bg-grayScale-100
    flex
    flex-col
  `,
  isSearch && tw`bg-white`,
]);

export const StyledMain = styled.main(({ isExcept, isFavoritePage }) => [
  tw`
    group-[:not(.is-infinite)]/infinite:h-screen
    group-[:not(.is-infinite)]/infinite:flex-1
    group-[:not(.is-infinite)]/infinite:overflow-y-auto
    group-[.is-infinite]/infinite:mt-11
    group-[.is-infinite]/infinite:pb-0
  `,
  !isExcept && tw`py-4`,
  isFavoritePage && tw`pt-14`,
]);

export const StyledContainer = styled(Container)`
  ${tw`h-full relative`}
`;
