import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { constants, GAEcommerceEvent, formatCurrencyRule } from 'utils';
import { useAuth } from 'hooks';
import {
  useExchangeQuery,
  fetchAddToCart,
  fetchCartCount,
} from 'reduxs/commonSlice';

const { pageTitle } = constants;

export const useCartOperation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { data: exchange } = useExchangeQuery(null, { refetchOnFocus: true });

  // 加車邏輯
  const handleAddToCart = async (platform, data) => {
    // 檢查是否登入，未登入導去綁定頁(透過會員中心觸發 member 檢查機制再導頁)
    if (!isAuthenticated) {
      navigate('/member');
      return;
    }

    // !confirm: 區分 platform
    // GA: 加入購物車(購買和加車參數相同)
    GAEcommerceEvent('add_to_cart', {
      items: [
        {
          item_id: `${data.itemId}`,
          item_name: data.productName,
          item_brand: pageTitle,
          item_category: '',
          item_category2: '',
          price: Math.ceil(formatCurrencyRule(data.salePrice, exchange).TWD),
          quantity: 1,
        },
      ],
    });

    try {
      await dispatch(fetchAddToCart({ platform, data })).unwrap();
      await dispatch(fetchCartCount()).unwrap();

      return {
        success: true,
        toast: {
          open: true,
          type: 'success',
          message: '已加入購物車',
        },
      };
    } catch (error) {
      console.log('handleAddToCart error:', error);
      return {
        success: false,
        toast: {
          open: true,
          type: 'warning',
          message: '稍後再試',
        },
      };
    }
  };

  return handleAddToCart;
};
