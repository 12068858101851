import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProxy, Service } from 'utils';

export const initialState = {
  loading: null,
  error: null,
  init: true,
  response: {
    announcements: {
      data: [],
      url: '',
      conditionTime: '',
    },
    banners: {
      data: [],
      url: '',
      conditionTime: '',
    },
    categoryAdvertisements: {
      data: [],
      url: '',
      conditionTime: '',
    },
    limitTimeSaleProductAdvertisements: {
      data: [],
      url: '',
      conditionTime: '',
    },
    rakumaProducts: [],
    mustStore: [],
    hotCategoryAdvertisements: {
      data: [],
      url: '',
      conditionTime: '',
    },
    brandMenu: [],
  },
};

// Ad 區塊與頁面
export const fetchRakumaHomeAd = createAsyncThunk(
  'rakumaHomeAd/fetchRakumaHomeAd',
  async (_, { getState }) => {
    const state = getState();
    const channelKey = state.common.adChannelKey;
    const adUrl = state.home.adUrl;
    const data = {};
    const urls = await serviceProxy({
      url: adUrl,
      except: true,
      cache: true,
    });

    for (let key in urls) {
      if (urls[key]?.[channelKey]) {
        const section = await serviceProxy({
          url: urls[key][channelKey][0],
          except: true,
          // cache: true,
        });
        data[key] = {
          data: section,
          url: urls[key].url,
          conditionTime: urls[key][channelKey]['conditionTime'] ?? '',
        };
      }
    }
    const { data: rakumaProducts } = await Service.rakumaHomeProduct();
    const { data: mustStore } = await Service.rakumaHomeStore();
    const { data: brandMenu } = await Service.brandMenu('category');
    data['rakumaProducts'] = rakumaProducts && Object.values(rakumaProducts);
    data['mustStore'] = mustStore?.list;
    data['brandMenu'] = formatHotBrand(brandMenu?.list);
    return data;
  }
);

const formatHotBrand = (payload) => {
  const res = [];

  if (payload) {
    payload.forEach((item) => {
      if (+item.homepageShow === 1) {
        const newItem = {
          id: item.brandId,
          image: item.brandIcon,
          brandName: item.brandName,
          homepageShow: item.homepageShow,
          url: `/search-result?brandId=${item.brandId}`,
          alt: item.brandName,
        };
        res.push(newItem);
      }
    });
  }

  return res;
};

const rakumaHomeAdSlice = createSlice({
  name: 'rakumaHomeAd',
  initialState,
  reducers: {
    resetRakumaHomeAd: (state) => {
      state.loading = initialState.loading;
      state.response = initialState.response;
      state.error = initialState.error;
      state.init = initialState.init;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRakumaHomeAd.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRakumaHomeAd.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.response = payload;
        state.init = false;
      })
      .addCase(fetchRakumaHomeAd.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetRakumaHomeAd } = rakumaHomeAdSlice.actions;

export default rakumaHomeAdSlice.reducer;
