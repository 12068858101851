import tw, { styled } from 'twin.macro';
import Button from 'components/button';

export const SearchContainer = styled.div`
  ${tw`
    flex
    my-1.5
    place-items-center
    bg-white
    rounded-full
    w-full
    border
    border-grayScale-400
    min-h-[2rem]
  `}
`;

export const StyledWrapper = styled.div`
  ${tw`
    max-h-5
    h-full
    px-1 
    border-l-gray-300 
    border-solid 
    border-l-[1px]
    flex-1
  `}
`;

export const StyledInput = styled.input`
  ${tw`text-sm outline-none border-none bg-transparent py-0 focus:outline-none pointer-events-auto text-black`}
  ${({ disabled }) => disabled && tw`pointer-events-none`}
`;

export const StyledButtonWrap = styled.div`
  ${tw`my-0.5 mr-1`}
`;

export const StyledButton = styled(Button)`
  ${tw`text-xs font-normal px-3 py-1 whitespace-nowrap`}
`;

export const StyledSelectorWrapper = styled.div`
  ${tw`
    text-xs
    font-normal
    pl-2
    pr-1
    h-full
    whitespace-nowrap
    min-w-[3.5rem]
    max-w-[3.5rem]
  `}
  select {
    padding: 0;
    background-size: contain;
    font-size: 14px;
  }
`;

export const StyledTextWrapper = styled.div`
  ${tw`
    text-sm
    font-normal
    pl-2
    pr-1
    h-full
    whitespace-nowrap
    min-w-[2.5rem]
    max-w-[2.5rem]
  `}
`;
