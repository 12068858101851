import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  Service,
  suspense,
  formatCurrencyRule,
  formatWeight,
  calculate,
} from 'utils';

// 列表
export const fetchCartList = createAsyncThunk(
  'cart/fetchCartList',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.cartList(reqData);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData.data;
  }
);

// 編輯數量: 考量用 debounce 處理 input change，fetch 行為也在 debounce 處理(包括成功與失敗)，這裡只單純更新 state
export const updateQuantity = createAsyncThunk(
  'cart/updateQuantity',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.cartUpdateQuantity({
        channelKey: 'drug',
        ...reqData.reqData,
      });

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return reqData;
  }
);

// 編輯規格
export const updateCartItem = createAsyncThunk(
  'cart/updateCartItem',
  async (reqData, { getState, rejectWithValue }) => {
    let resData = {};
    let retry = 0;
    const state = getState();
    const { tempData } = state.cart;

    const {
      itemId,
      originalPrice,
      salePrice,
      productImageUrl,
      quantity,
      maxOrderQuantity,
      weight,
      productWidth,
      productHeight,
      productLength,
      specialTagId,
      status,
      optionNames,
    } = reqData;

    // 若商品已下架，不打更新 API 且需更新 list(status: 0)
    if (reqData.status < 1) {
      return {
        cartKey: tempData.cartKey,
        resData: {
          ...reqData,
          id: tempData.id,
          maxOrderQuantity: reqData.quantity,
        },
      };
    }

    do {
      resData = await Service.cartUpdateCartItem({
        channelKey: 'drug', // 目前只有商城先寫死
        id: tempData.id,
        productItemId: `${itemId}`,
        listPrice: originalPrice,
        salePrice: salePrice,
        productImageUrl,
        productWeight: weight,
        productLength,
        productWidth,
        productHeight,
        quantity,
        maxOrderQuantity,
      });

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return {
      cartKey: tempData.cartKey,
      optionNames: optionNames, // 不與購物車欄位混在一起，因此放外面額外處理
      changedItemId: tempData.productItemId, // 變規格前的 itemId
      resData: {
        id: tempData.id,
        productItemId: `${itemId}`,
        listPrice: originalPrice,
        salePrice: salePrice,
        originalSalePrice: salePrice,
        productImageUrl,
        productWeight: weight,
        originalProductWeight: weight,
        quantity,
        originalQuantity: quantity,
        maxOrderQuantity,
        specialTagId: specialTagId,
        status,
      },
    };
  }
);

// 刪除品項 (API 允許多筆刪除 Ex.刪除整車)
export const deleteCartItem = createAsyncThunk(
  'cart/deleteCartItem',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.cartDeleteCartItem({
        channelKey: reqData.platform,
        cartItems: [reqData.id],
      });

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return {
      code: resData.code,
      resData: reqData,
    };
  }
);

// 更新數量 state: 統一由這裡管理邏輯
const updateStateQuantity = (data, payload) =>
  data?.map((obj) => {
    const { id, quantity } = payload;
    if (obj.id === id) {
      obj.originalQuantity = quantity;
      obj.quantity = quantity;
    }
    return obj;
  });

// 更新規格與其他欄位 state: 統一由這裡管理邏輯(Ex. 該商品被下架或規格被下架或缺貨等其他需要被更新的欄位)
const updateStateItemData = (data, payload, optionNames) =>
  data?.map((obj) => {
    const { id, status } = payload;
    if (obj.id === id) {
      obj = { ...obj, ...payload };

      // optionNames 因為結構不一樣需額外處理給購物車，但僅針對上架商品處理
      if (status) {
        obj.productItemName = obj.productItemName.map((item, idx) => {
          item.optionName = optionNames[idx];
          return item;
        });
      }
    }
    return obj;
  });

// 更新刪除後的 state: 統一由這裡管理邏輯
const updateStateByDelete = (data, payload) =>
  data?.filter((obj) => obj.id !== payload);

const initialState = {
  cartItems: null,
  groupNames: {},
  selectedItems: {},
  diffItemIds: [],
  estimateOrderCartKey: '',
  checkLimitCartKey: '',
  pathFrom: '',
  overLimit: {},
  tempExchange: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setEstimateOrderCartKey: (state, { payload }) => {
      state.estimateOrderCartKey = payload;
    },
    setCheckLimitCartKey: (state, { payload }) => {
      state.checkLimitCartKey = payload;
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload;
    },
    // 編輯規格完後，更新畫面用
    setTempData: (state, { payload }) => {
      state.tempData = payload;
    },
    setPathFrom: (state, { payload }) => {
      state.pathFrom = payload;
    },
    setOverLimitVolume: (state, { payload }) => {
      state.overLimit = payload;
    },
    setTempExchange: (state, { payload }) => {
      state.tempExchange = payload;
    },
    resetCartData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartList.fulfilled, (state, { payload }) => {
        const { cartItems, groupNames } = payload;
        state.loading = false;
        state.cartItems = cartItems;
        state.groupNames = groupNames;

        // 收集同商品不同規格的 itemId
        state.diffItemIds = Object.entries(cartItems).reduce(
          (acc, [key, value]) => {
            const result = value
              .filter((item) =>
                value.some(
                  (obj) => obj.productId === item.productId && obj !== item
                )
              )
              .flatMap(({ productItemId }) => +productItemId);

            acc[key] = result;
            return acc;
          },
          {}
        );
      })
      .addCase(fetchCartList.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(updateQuantity.fulfilled, (state, { payload }) => {
        const { cartKey, reqData } = payload;

        // 數量與第一次加車的數量都需要同步更新，不然選取後的金額加總會錯
        state.cartItems[cartKey] = updateStateQuantity(
          state.cartItems[cartKey],
          reqData
        );

        // 若已選取的項目也需要一起被更新
        if (state.selectedItems[cartKey]) {
          state.selectedItems[cartKey] = updateStateQuantity(
            state.selectedItems[cartKey],
            reqData
          );
        }
      })
      .addCase(updateCartItem.fulfilled, (state, { payload }) => {
        const { cartKey, optionNames, changedItemId, resData } = payload;

        // 規格與第一次加車的規格都需要同步更新，不然選取後的金額加總會錯
        state.cartItems[cartKey] = updateStateItemData(
          state.cartItems[cartKey],
          resData,
          optionNames
        );

        // 變規格: 踢除舊的換新的
        state.diffItemIds[cartKey] = [
          ...state.diffItemIds[cartKey].filter((id) => id !== +changedItemId),
          +resData.productItemId,
        ];

        // 若已選取的項目也需要一起被更新
        if (state.selectedItems[cartKey]) {
          state.selectedItems[cartKey] = updateStateItemData(
            state.selectedItems[cartKey],
            resData,
            optionNames
          );
        }
      })
      .addCase(deleteCartItem.fulfilled, (state, { payload }) => {
        const { cartKey, id, productItemId } = payload.resData;

        state.cartItems[cartKey] = updateStateByDelete(
          state.cartItems[cartKey],
          id
        );

        // 排除同商品不同規格
        state.diffItemIds[cartKey] = state.diffItemIds[cartKey].filter(
          (itemId) => itemId !== +productItemId
        );

        // 若已選取的項目也需要一起被更新
        if (state.selectedItems[cartKey]) {
          state.selectedItems[cartKey] = updateStateByDelete(
            state.selectedItems[cartKey],
            id
          );
        }

        // 若刪除車子裡的唯一一筆資料則連 key 都一併刪除，不然介面上會留有車子的外皮
        if (!state.cartItems[cartKey].length) {
          delete state.cartItems[cartKey];
          delete state.selectedItems[cartKey];
        }
      })
      .addCase(deleteCartItem.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      });
  },
});

export const {
  setEstimateOrderCartKey,
  setCheckLimitCartKey,
  setTempData,
  setSelectedItems,
  setPathFrom,
  setOverLimitVolume,
  setTempExchange,
  resetCartData,
} = cartSlice.actions;
export default cartSlice.reducer;

/**
 * 校正成主站算法
 *  - 此 selector 只負責 "品項 * 數量" 小計與單位轉換，所有勾選加總與行銷工具邏輯拆成別的 selector 管理
 *  - 金額需先轉匯率後再無條件進位才能算總計(解決 $1 之差問題)
 *  - 為方便後續與行銷工具做比對，金額與重量皆已轉換單位
 *    > 重量: 公克(g)轉公斤(kg)
 *    > 金額: 外幣(日幣)轉台幣
 */

const items = (state) => state.cart.selectedItems;
const tempExchange = (state) => state.cart.tempExchange;
const subtotalSelector = createSelector(
  [items, tempExchange],
  (selectedItems, exchange) => {
    const data = Object.keys(selectedItems);
    if (!data.length) return {};

    const tempData = data.reduce((acc, key) => {
      acc[key] = selectedItems[key].map(
        ({ productWeight, listPrice, salePrice, quantity }) => {
          /**
           * 品項小計欄位
           *  - 重量小計(轉公斤) = 公克 * 數量
           *  - 特價小計(無條件進位) = 特價(日幣) * 數量 * 匯率
           *  - 原價小計(無條件進位) = 原價(日幣) * 數量 * 匯率
           */
          return {
            amountWeight: formatWeight(productWeight * quantity),
            amountListPrice: Math.ceil(
              formatCurrencyRule(listPrice * quantity, exchange).TWD
            ),
            amountSalePrice: Math.ceil(
              formatCurrencyRule(salePrice * quantity, exchange).TWD
            ),
          };
        }
      );

      return acc;
    }, {});

    return tempData;
  }
);

/**
 * 加總: 金額與重量
 *  - 此 selector 只負責 "勾選品項小計" 的加總
 *  - 所有單位轉換皆已在 subtotalSelector 處理完畢，不需再轉換單位
 *  - 欄位說明
 *    > weight: 重量(公斤)總計，因重量為浮點數需用 calculate() 處理
 *    > priceOrigin: 原價總計
 *    > priceSale: 特價總計
 *    > spread: 原價總計與特價總計之價差(畫面顯示用)
 */
export const totalSelector = createSelector(subtotalSelector, (subtotal) => {
  const data = Object.keys(subtotal);
  if (!data.length) return {};

  const tempData = data.reduce((obj, key) => {
    obj[key] = subtotal[key].reduce(
      (acc, curr) => {
        const sum = {
          weight: calculate(acc.weight + curr.amountWeight),
          priceOrigin: acc.priceOrigin + curr.amountListPrice,
          priceSale: acc.priceSale + curr.amountSalePrice,
        };

        // 特價折扣(價差 = 原價總計 - 特價總計)
        sum.spread = calculate(sum.priceOrigin - sum.priceSale);

        return sum;
      },
      {
        weight: 0,
        priceOrigin: 0,
        priceSale: 0,
        spread: 0,
      }
    );

    return obj;
  }, {});

  return tempData;
});

// TODO: 國際運費部分抓門檻的方式有問題，之後要再優化
// 運費規則: 勾選品項加總後，過濾出符合國際與國內的運費門檻是哪一個
export const chargeFeeSelector = createSelector(
  (state) => state.market.international,
  (_, data) => data,
  totalSelector,
  (international, platform, total) => {
    let result = {};

    // 若未提供頻道 key 則統一取全站(all)
    const config = international[0]?.rules[platform]
      ? international[0]?.rules[platform]
      : international[0]?.rules.all;

    Object.keys(total).forEach((cartKey) => {
      const { priceSale: tempPrice } = total[cartKey];
      const achieve =
        config?.filter(
          ({ minAmount, fee }) => tempPrice >= minAmount && fee === 0
        ) ?? [];
      const incomplete =
        config?.find(({ minAmount }) => tempPrice < minAmount) ?? {};
      const spread = calculate(
        Object.keys(incomplete).length ? incomplete.minAmount - tempPrice : 0
      );
      const minAmount = config?.find(({ fee }) => fee === 0)?.minAmount ?? 0;

      result = {
        ...result,
        [cartKey]: {
          ...result[cartKey],
          achieve,
          incomplete,
          spread,
          fee: config?.[0].fee ?? 0,
          minAmount,
          isGoal: false,
        },
      };

      // 符合免運門檻價差要是 0
      if (achieve.find(({ fee }) => fee === 0)) {
        result[cartKey].spread = 0;
        result[cartKey].isGoal = true;
      }
    });

    return result;
  }
);
