import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProxy } from 'utils';

export const initialState = {
  loading: null,
  error: null,
  init: true,
  response: {
    limitTimeSaleProducts: [],
    fortyEightHour: [],
    latestProducts: [],
    salesRanking: [],
    highlyRatedJapanAmazonProducts: [],
    everyoneIsBuying: [],
    veryDeliciousSnack: [],
    beautyCare: [],
    bodyCare: [],
    seasonalProducts: [],
  },
};

export const fetchHomeProduct = createAsyncThunk(
  'home/fetchHomeProduct',
  async (_, { getState }) => {
    const state = getState();
    const productUrl = state.home.productUrl;
    const data = { ...initialState.response };

    const urls = await serviceProxy({
      url: productUrl,
      except: true,
      cache: true,
    });

    for (let key in urls) {
      if (urls[key]) {
        const section = await serviceProxy({
          url: urls[key],
          except: true,
          cache: true,
        });
        data[key] = section.map((item) => {
          if (item.id) {
            return { ...item, key };
          }
          return item;
        });
      }
    }
    return data;
  }
);

const homeProductSlice = createSlice({
  name: 'homeProduct',
  initialState,
  reducers: {
    resetHomeProduct: (state) => {
      state.loading = initialState.loading;
      state.response = initialState.response;
      state.error = initialState.error;
      state.init = initialState.init;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHomeProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.response = payload;
        state.init = false;
      })
      .addCase(fetchHomeProduct.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetHomeProduct } = homeProductSlice.actions;

export default homeProductSlice.reducer;
