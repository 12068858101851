/**
 * 檢查單一物件的有效顯示時間
 * @param {*} item object
 * @returns boolean
 */
export const checkExpiration = (item) => {
  const { startAt, endAt, startTime, endTime } = item;
  if (startAt && endAt) {
    const startDate = new Date(startAt.replace(/-/g, '/')).getTime();
    const endDate = new Date(endAt.replace(/-/g, '/')).getTime();
    const currentDate = new Date().getTime();
    const isValid = startDate <= currentDate && currentDate <= endDate;
    return isValid;
  } else if (startTime && endTime) {
    const startDate = new Date(startTime.replace(/-/g, '/')).getTime();
    const endDate = new Date(endTime.replace(/-/g, '/')).getTime();
    const currentDate = new Date().getTime();
    const isValid = startDate <= currentDate && currentDate <= endDate;
    return isValid;
  }
  return false;
};

/**
 * 檢查陣列中各物件的有效顯示時間, 回傳第一筆或多筆
 * @param {*} data array
 * @param {*} type string: single | multiple
 * @returns single -> object, multiple -> array, no match -> null
 */
export const checkArrayExpiration = (data, type) => {
  if (!data || !data.length) {
    return null;
  }
  const results = data?.filter((item) => {
    if (item && checkExpiration(item)) return item;
  });
  if (!results || !results.length) {
    return null;
  }
  return type === 'single' ? results[0] : results;
};
