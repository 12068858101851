import { configureStore } from '@reduxjs/toolkit';

// common
import common, { exchangeApi } from './commonSlice';
import market from './marketSlice';
import menu from './menuSlice';
import rakumaMenu from './rakumaMenuSlice';
import variation from './variationSlice';
import userInfo from './userInfoSlice';
import familyInfo from './familyInfoSlice';
import error from './errorSlice';

// pages: 訪客(含 infinite scroll)
import home from './homeSlice';
import homeAd from './homeAdSlice';
import rakumaHomeAd from './rakumaHomeAdSlice';
import rakumaSeries from './rakumaSeriesSlice';
import homeProduct from './homeProductSlice';
import product from './productSlice';
import search, { searchApi, brandApi } from './searchSlice';
import rakumaSearch from './rakumaSearchSlice';
import searchFilter from './searchFilterSlice';
import promotion, { promotionAPI } from './promotionSlice';
import { categoryApi } from './categorySlice';

// pages: 需要權限
import cart from './cartSlice';
import pay from './paySlice';
import stores from './storeSlice';
import receiver from './receiverSlice';
import order, { orderListAPI } from './orderSlice';
import orderQA from './qaSlice';
import qaList from './qaListSlice';
import member from './memberSlice';
import coupon from './couponSlice';
import favorite, { favoriteApi } from './favoriteSlice';

export const store = configureStore({
  reducer: {
    common,
    market,
    menu,
    rakumaMenu,
    variation,
    userInfo,
    familyInfo,
    error,
    home,
    homeProduct,
    homeAd,
    rakumaHomeAd,
    rakumaSeries,
    product,
    search,
    rakumaSearch,
    searchFilter,
    cart,
    pay,
    stores,
    receiver,
    order,
    member,
    orderQA,
    promotion,
    coupon,
    favorite,
    qaList,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    [promotionAPI.reducerPath]: promotionAPI.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [orderListAPI.reducerPath]: orderListAPI.reducer,
    [favoriteApi.reducerPath]: favoriteApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(exchangeApi.middleware)
      .concat(promotionAPI.middleware)
      .concat(categoryApi.middleware)
      .concat(searchApi.middleware)
      .concat(brandApi.middleware)
      .concat(orderListAPI.middleware)
      .concat(favoriteApi.middleware),
  devTools: process.env.REACT_APP_ENV !== 'production', // 正式環境時，資料不顯示在dev tool
});
